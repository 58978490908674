@tailwind base;
@tailwind components;
@tailwind utilities;
// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap')

@font-face {
  font-weight: 400;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Regular.otf");
}

@font-face {
  font-weight: 300;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Thin.otf");
}

@font-face {
  font-weight: lighter;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Light.otf");
}

@font-face {
  font-weight: 500;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Medium.otf");
}

@font-face {
  font-weight: 600;
  font-family: "exo";
  src: url("./assets/fonts/Exo-DemiBold.otf");
}

@font-face {
  font-weight: bold;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Bold.otf");
}

@font-face {
  font-weight: 900;
  font-family: "exo";
  src: url("./assets/fonts/Exo-Black.otf");
}

$gray: #a6a6a6;
$gray2: #332b27;
$gray3: #a5a6a7;
$gray4: #7c7c7d;
$red: #dc3838;
$purple: #9200b7;
$red2: #d73d3b;

body {
  margin: 0;
  font-family: "exo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu {
  .active {
    position: relative;

    &:after {
      content: "";
      width: 7px;
      display: flex;
      height: 7px;
      background: white;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translate(-50%);
    }
  }
}

.nav-widget {
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 2;

  ul {
    position: relative;
  }

  li.active {
    background-color: $red !important;

    svg {
      fill: white;
    }

    .cls-1 {
      fill: white;
    }
  }

  li {
    margin-bottom: 30px;
    background: rgb(255 255 255 / 65%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 2;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      background: rgb(255 255 255 / 65%);
      height: 30px;
      z-index: 1;
      bottom: -30px;
    }
  }

  :last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }
}

.section {
  height: calc(100vh - 68px);
}

.banner .banner-bg {
  background-image: url("./assets/img//yucana_dog.png");
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

.text-container {
  top: 50%;
  transform: translate(0, -50%);
  padding-left: 10%;
  z-index: 2;
}

.txt-white {
  color: white;
  font-size: 3.5rem;
  line-height: 1.4;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}

.txt-weapon {
  font: normal normal medium 18px/24px Exo;
  letter-spacing: 1.8px;
  color: #a6a6a6;
  text-transform: uppercase;
  opacity: 1;
  color: #a6a6a6;
  text-align: left;
}

.txt-weapon-popularity {
  font: normal normal medium 20px/42px Exo;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-size: 20px;
  line-height: 1.4;
  text-align: left;
  font-weight: normal;
  letter-spacing: 2px;
}

.txt-weapon-popularity-percentage {
  letter-spacing: 5px;
  font-size: 50px;
  color: #dc3838;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal 800 50px/66px "Exo";
}

.weapon-properties {
  font-size: 20px;
  color: #dc3838;
  text-transform: capitalize;
  opacity: 1;
}

.weapon-statistic {
  font: normal normal bold 20px/42px Exo;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: capitalize;
}

.statisticBar {
  background-color: #dc3838;
}

.progress-container {
  width: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.progress-bar {
  width: 100%;
  height: 12px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #dc3838;
}

.map-description {
  font: normal normal medium 18px/40px Exo;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 550px;
}

.map-bg {
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  isolation: isolate;
  position: relative;
}

.map-bg::after {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: black;
  opacity: 0.3;
}

.news-carousel {
  .new-card-container:first-child {
    margin-left: 8%;
  }
}

.new-card-container {
  margin-right: 40px;
}

.new-card-image-container {
  width: 305px;
  height: 250px;
  box-shadow: 0px 0px 15px #ffffff0d;
  border-radius: 55px;
  opacity: 1;
  margin-bottom: 20px;
}

.news-title-container {
  width: 100%;
}

.news-title {
  width: 35%;
  margin: auto;
  margin-top: 100px;
  color: white;
  font-size: 3.5rem;
  line-height: 1.4;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}

.gradient {
  background: -webkit-linear-gradient(262deg, $purple, $red2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.4;
  text-transform: uppercase;
}

// buttons styles
.style1 {
  border-radius: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  text-transform: uppercase;
  color: white;
  padding: 0 30px;
  height: 45px;
  font-size: 16px;
}

.style2 {
  border-radius: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  text-transform: uppercase;
  color: white;
  padding: 0 30px;
  height: 45px;
  background: -webkit-linear-gradient(258deg, $purple, $red2);
  font-size: 16px;
}

.swiper {
  height: calc(100vh - 68px);
  width: 100%;
}

.pet-bg,
.weapon-bg {
  width: 800px;
  height: 600px;
  top: 0px;
  right: 40px;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.pets-container {
  background-image: url("./assets/img/petsAndWeaponsBackground.webp");
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;

  .text-container {
    top: inherit;
    transform: inherit;
    padding-left: 10%;
    z-index: 2;
  }

  .pet {
    opacity: 0;
    height: 0;

    .cont {
      position: relative;
      z-index: 2;
      max-width: 500px;
    }
  }

  .currentPet {
    opacity: 1;
    transition: opacity 0.3s linear 0.2s;
    height: 100%;
  }

  .weapon-actions {
    width: 60%;
    left: 40px;

    &::after {
      content: "";
      bottom: 7px;
      position: absolute;
      background: $gray3;
      height: 2px;
      width: calc(100% - 3.4rem);
      left: 28px;
      z-index: 1;
    }

    li {
      padding-bottom: 30px;
      position: relative;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgb(255 255 255 / 35%);
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(0, -50%);
        z-index: 2;
      }

      span {
        color: rgb(255 255 255 / 35%);
      }
    }

    .icon {
      background-color: $gray3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .active {
      &::before {
        background-color: $red2;
      }

      .icon {
        background-color: $red2;
      }

      svg {
        fill: white;
      }

      span {
        color: white;
      }
    }

    svg {
      fill: $gray4;
    }
  }

  .pet-actions {
    width: calc(100% - 80px);
    left: 40px;
    z-index: 3;

    &::after {
      content: "";
      bottom: 7px;
      position: absolute;
      background: $gray3;
      height: 2px;
      width: calc(100% - 3.4rem);
      left: 28px;
      z-index: 1;
    }

    li {
      padding-bottom: 30px;
      position: relative;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgb(255 255 255 / 35%);
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(0, -50%);
        z-index: 2;
      }

      span {
        color: rgb(255 255 255 / 35%);
      }
    }

    .icon {
      background-color: $gray3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .active {
      &::before {
        background-color: $red2;
      }

      .icon {
        background-color: $red2;
      }

      svg {
        fill: white;

        path {
          fill: white !important;
        }
      }

      span {
        color: white;
      }
    }

    svg {
      fill: black !important;

      path {
        fill: black !important;
      }
    }
  }

  .weapon-actions-cont {
    max-width: 50%;
    left: 50% !important;
    transform: translate(-50%, 0);
  }
}

// blog page
.blog {
  .blog-icon {
    svg {
      fill: white;
    }
  }

  .desc {
    padding-bottom: 20px;

    p {
      margin-bottom: 20px;
      line-height: 1.8;
    }

    h2 {
      color: white;
      font-size: 1.875rem;
      line-height: 2.25rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h2 {
      color: white;
      font-size: 1.875rem;
      line-height: 2.25rem;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
}

// about
.about {
  .bg-intro {
    width: 800px;
    height: 800px;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
  }
}

// mobile
@media (max-width: 1520px) {
  .pets-container .text-container {
    padding-left: 0;
  }
}

@media (max-width: 1400px) {
  .pets-container .text-container {
    padding-left: 5%;
  }
}

@media (max-width: 1280px) {

  .pet-bg,
  .weapon-bg {
    width: 500px;
    height: 500px;
    right: -50px;
  }
}

@media (max-width: 1200px) {
  .about {
    .bg-intro {
      width: 500px;
      height: 500px;
    }
  }
}

@media (max-width: 1024px) {
  .section {
    height: inherit;
    position: relative;
  }

  .banner {
    .banner-bg {
      width: 300px;
      height: 300px;
      transform: inherit;
      margin: 0 auto;
    }
  }

  .text-container {
    transform: inherit;
    padding-left: 0 !important;

    h2 {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  .pets-container {
    background-image: inherit;
    padding: 40px 0;
    overflow: hidden;

    .container {
      position: relative;
    }

    .pet {

      .pet-bg,
      .weapon-bg {
        width: 100%;
        top: 100px;
        height: 350px;
        right: 0;
      }

      .weapon-bg {
        top: 150px;
      }

      .cont {
        padding-top: 400px;
        margin-bottom: 5px;
        max-width: 100%;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }

      h3 {
        margin: 5px 0;
        font-size: 0.7rem;
      }

      img {
        width: 1.5rem;
      }

      .leading-loose {
        font-size: 0.7rem;
        line-height: 1.3;
      }
    }

    .pet-actions {
      bottom: inherit;
      top: 40px;
      width: 100% !important;
      left: 10px !important;

      ul {
        overflow-x: scroll;

        &::after {
          display: none;
        }
      }

      li {
        margin-right: 30px;
      }

      &::after {
        display: none !important;
      }
    }

    .weapon-actions-cont {
      max-width: 100%;
      transform: inherit;
    }
  }

  .pets-container.weapon-cont {
    .cont {
      padding-top: 500px;
    }
  }

  .cont-info {
    align-items: center;
    justify-content: center;
  }

  #maps {
    .pet-actions {
      top: 0;
    }

    .pet .txt-white {
      padding-top: 140px;
    }

    .map-description {
      width: 100%;
    }
  }
}

@media (max-width: 998px) {
  .about {
    .bg-intro {
      width: 400px;
      height: 400px;
    }
  }
}

@media (max-width: 768px) {
  .text-container {
    h2 {
      font-size: 1.5rem;
    }
  }

  .style1 {
    margin: 0 auto 20px;
  }

  .style2 {
    margin: 0 auto 
  }

  // about
  .about {
    .text-container {
      padding: 0 15px;
      top: 0;
      transform: inherit;
      height: 100%;
    }

    .bg-intro {
      width: 300px;
      height: 300px;
      top: inherit;
      bottom: 20px;
      right: 50%;
      transform: translate(50%, 0);
    }
  }
}

@media (max-width: 640px) {
  .banner {
    background-position: bottom center;
  }

  .nav-widget {
    top: inherit;
    bottom: 40px;
    transform: translate(-50%, 0px);
    left: 50%;
    width: 95%;
    height: 50px;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 40px;
      width: 100%;

      &:after {
        display: block !important;
        content: "";
        bottom: 50%;
        position: absolute;
        background: #a5a6a7;
        height: 3px;
        width: 80%;
        left: 38px;
        z-index: 1;
      }

      li {
        margin-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .text-container {
    padding: 0 15px;
    top: 0;
    transform: inherit;
  }

  .weapon-actions-cont {
    max-width: inherit;
    left: inherit;
    transform: inherit;
  }

  .weapon-cont {
    .txt-weapon {
      font-size: 0.5rem !important;
    }

    .txt-weapon-popularity,
    .txt-weapon-popularity-percentage,
    .txt-white,
    .weapon-statistic p {
      font-size: 1rem !important;
      margin-bottom: 0 !important;
    }

    .weapon-statistic {
      margin-top: 0;

      .value {
        h3 {
          line-height: 1.5;
        }
      }
    }

    .txt-weapon-popularity-percentage {
      width: 40px !important;
      height: 40px !important;
      margin: 0 !important;
    }

    .attribute {
      h3 {
        line-height: 1.5;
      }
    }

    .weapon-properties {
      line-height: 1.5;
    }
  }

  #maps {
    padding-top: 0;

    .weapon-actions-cont {
      background: black !important;
      left: 0 !important;
    }

    ul {
      padding: 0 15px;
    }

    li {
      &:last-child {
        margin-right: 0;
      }
    }

    .currentPet {
      .txt-white {
        font-size: 2rem !important;
      }
    }

    .map-description {
      width: 100%;
      font-size: 0.9rem !important;
      line-height: 2;
      letter-spacing: 2px;
    }
  }

  #news {
    .title {

      .txt-white,
      span {
        font-size: 2rem !important;
        margin-bottom: 20px;
      }
    }

    .new-card-image-container {
      width: 100%;
      height: 180px;
      border-radius: 20px;
    }

    .new-card-container {

      p,
      span {
        font-size: 0.7rem;
      }
    }

    .bottom-section {
      font-size: 0.7rem;
    }
  }
}

// blog
.blog {
  .bg-img {
    height: 200px;
  }
}

::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bg-box {
  box-shadow: 1px 2px 14px 1px #c8305659;
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  border: 1px solid #c8305659;

  .bg-cover {
    height: 300px;
    width: 400px;
    max-width: 100%;
  }
}

// Posho 1
.main-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.second-overlay {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(245, 24, 127, 0.877);
  padding: 20px;
}

.third-overlay {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(50, 50, 50, 0.8));
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  padding: 20px;
}

.black-overlay {
  background: black;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  padding: 50px;
  margin: 10px;
}

// Posho 2
.buttonrng {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #fcfcfc;
  background: #d82ead;
  background: linear-gradient(90deg, rgb(204, 52, 79) 0%, rgb(209, 60, 122) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(204, 52, 79, .64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.buttonrng::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #e600e6;
  box-shadow: 0 0 60px rgba(176, 0, 230, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.buttonrng:hover,
.buttonrng:focus {
  color: #ffffff;
  transform: translateY(-6px);
}

.buttonrng:hover::before,
.buttonrng:focus::before {
  opacity: 1;
}

.buttonrng::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #e6008e;
  /* New green-ish tone */
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.buttonrng:hover::after,
.buttonrng:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }

  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

// Posho 3
.containerp3 {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover,
  &:active {

    .topp3,
    .bottomp3 {

      &:before,
      &:after {
        margin-left: 200px;
        transform-origin: -200px 50%;
        transition-delay: 0s;
      }
    }

    .centerp3 {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }
}

.topp3,
.bottomp3 {

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 200vmax;
    height: 200vmax;
    top: 50%;
    left: 50%;
    margin-top: -100vmax;
    transform-origin: 0 50%;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    z-index: 10;
    opacity: 0.65;
    transition-delay: 0.2s;
  }
}

.topp3 {
  &:before {
    transform: rotate(45deg);
    background: #B82273;
  }

  &:after {
    transform: rotate(135deg);
    background: #B82273;
  }
}

.bottomp3 {
  &:before {
    transform: rotate(-45deg);
    background: #2ED881;
  }

  &:after {
    transform: rotate(-135deg);
    background: #2ED881;
  }
}

.centerp3 {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  transition-delay: 0s;
  color: #333;

  .inputp3 {
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
  }
}

// Posho 4
.buttonrng2 {
  width: auto;
  padding: 15px;
  border: 1px solid #B82273;
  background-color: #555;
}

.buttonrng2:hover {
  transition: all 0.5s;
  background-color: #2ED881;
  border-radius: 50%;
}

//Posho 5
.img-b-shadow {
  filter: drop-shadow(0 0 10px rgba(255, 1, 1, 0.8));
}

// Posho 6
.p-overflow {
  overflow: auto;
  height: 100px;
  width: 350px;
  border: 2px solid pink;
  background: #332b27;
  color: white;
}

// Posho 7
.rainbow-text {
  background: linear-gradient(90deg, #00ffff, #ff00c3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.rainbow-text-2 {
  background: linear-gradient(90deg, #f39b49, #eb1b1b);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

// Posho 8
.register-form-group {
  margin-bottom: 20px;
}

.register-form-input[type="text"],
.register-form-input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: black;
}

.register-form-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #18be26;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.register-form-button:hover {
  background-color: #066913;
}

.wombat-login-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.image-container {
  margin-bottom: 20px;
  text-align: center;
}

.wombat-login-text {
  font-size: 36px;
  font-weight: bold;
  font-family: 'YourWiderFont', sans-serif;
  margin-bottom: 20px;
}

.message-container {
  background-color: #FFE4E1;
  color: #FF4F24;
  padding: 20px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.message-container:hover {
  transform: translateY(-2px);
}

.signin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.signin-buttons button {
  background-color: #2ED881;
  color: #FFFFFF;
  border: none;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  width: 80%;
  max-width: 300px;
  display: flex;
  align-items: center;
}

.signin-buttons button svg,
.signin-buttons button img {
  margin-right: 10px;
}

// Posho 9

.footer-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-content p {
  color: white;
}

.footer-social-icon {
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(172, 25, 25, 0.589);
}

.footer-social-icon:hover {
  color: $red;
  background-color: $red;
}

.footer-social-icon svg {
  width: 20px;
  height: 20px;
  fill: #fff;
  filter: drop-shadow(0 0 10px rgba(255, 0, 21, 0.8));
}

// Wombat Download
$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7))
  }
}

// Download
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 95%;

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 53);
  }

  .slide {
    height: 100%;
    width: 250%;
  }

  .slide {
    height: 100%;
    width: 250px;
    border: 1px solid white;
  }
}

/* LeftMenu.css */
.left-menu {
  width: 20%;
  color: #FFFFFF;
  padding: 20px;
  text-align: left;
}

.left-menu ul {
  list-style-type: none;
  padding: 0;
}

.left-menu ul li {
  margin-bottom: 10px;
}

.left-menu ul ul {
  margin-left: 20px;
}

.left-menu ul li a {
  text-decoration: none;
  color: #FFFFFF;
}

.left-menu ul li a.active {
  font-weight: bold;
  color: #DC3838;
}